import * as React from "react"
import { HeadFC } from "gatsby"


const NotFoundPage = () => {
  return (
    <div>
      <h1>page not found</h1>
    </div>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>Not found</title>
